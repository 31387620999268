import { PermissionCode, ResourceCode } from '@/const/code';

export default [
  {
    path: '/',
    name: 'portalDashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      resource: ResourceCode.PORTAL_DASHBOARD,
    },
  },
]
