export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/index'),
    meta: {
      // resource: 'NEWS',
    },
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('@/views/changePassword/Index'),
    meta: {
      // resource: 'NEWS',
    },
  },

]
