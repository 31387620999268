import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    reportAvgStudentClass: [],
  },
  getters: {
    reportAvgStudentClass: state => state.reportAvgStudentClass,

  },
  mutations: {
    SET_AVG_STUDENT: (state, reportAvgStudentClass) => { state.reportAvgStudentClass = reportAvgStudentClass },
  },

  actions: {
    async getReportAvgStudentClass({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportCanhBaoHocTap', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_AVG_STUDENT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

  },
}
