import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    studentCgpa: [],
  },
  getters: {
    studentCgpa: state => state.studentCgpa,

  },
  mutations: {
    SET_STUDENT_CGPA: (state, studentCgpa) => { state.studentCgpa = studentCgpa },
  },

  actions: {
    async getStudentCgpa({ commit }, params) {
      try {
        const response = await axios.get('uni/studentCgpa/school_year_level', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_STUDENT_CGPA', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

  },
}
