import AuthError from '@/libs/errors/auth-error'
import CustomError from '@/libs/errors/custom-error'
import { getUser } from '@/auth/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export const handleCatch = e => {
  if (e.code === 'ECONNABORTED') throw new CustomError(-1, 'Đã hết thời gian xử lý')
  const response = e?.response
  if (response) {
    const { data } = response
    const {
      code,
      message,
    } = data
    switch (response.status) {
      case 401:
        throw new AuthError(code, message)
      default:
        throw new CustomError(code, message)
    }
  }
}

export const hasPermissionForResource = (permission, resource) => {
  const user = getUser()
  const { abilities } = user
  return abilities.findIndex(ability => (ability.action === permission && ability.subject === resource)
    || (ability.action === PermissionCode.MANAGE && ability.subject === ResourceCode.ALL)) > -1
}

export const mapDropdown = e => {
  const mapData = e.map(item => ({
    value: item.id,
    label: item.name,
  }))
  return mapData
}

export const isSystemAdmin = () => hasPermissionForResource(PermissionCode.MANAGE, ResourceCode.ALL)

export const getIconFile = filename => {
  const extension = filename.split('.').pop()
  switch (extension) {
    case 'xls':
    case 'xlsx':
      return require('@/assets/images/icons/file-icons/xls.png')
    case 'doc':
    case 'docx':
      return require('@/assets/images/icons/file-icons/doc.png')
    case 'pdf':
      return require('@/assets/images/icons/file-icons/pdf.png')
    default:
      return require('@/assets/images/icons/file-icons/unknown.png')
  }
}

export default {
  handleCatch,
  hasPermissionForResource,
  isSystemAdmin,
  getIconFile,
}
