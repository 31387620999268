import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    articles: [],
    articlesAll: [],
    articlesHL: [],
    total: 0,
    statuses: [],
    category: [],
    article: {},
  },
  getters: {
    articles: state => state.articles,
    articlesAll: state => state.articlesAll,
    article: state => state.article,
    articlesHL: state => state.articlesHL,
    category: state => state.category,
    total: state => state.total,
    statuses: state => state.statuses,
  },
  mutations: {
    SET_ARTICLES: (state, articles) => { state.articles = articles },
    SET_ARTICLES_ALL: (state, articlesAll) => { state.articlesAll = articlesAll },
    SET_ARTICLES_HL: (state, articlesHL) => { state.articlesHL = articlesHL },
    SET_ARTICLE: (state, article) => { state.article = article },
    SET_TOTAL: (state, total) => { state.total = total },
    SET_CATEGORY: (state, category) => { state.category = category },
  },

  actions: {

    async getNews({ commit }, params) {
      try {
        const response = await axios.get('uni/article', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_ARTICLES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getNewsHighLight({ commit }, params) {
      try {
        const response = await axios.get('uni/article/highlight', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_ARTICLES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getArticles({ commit }, body) {
      try {
        const response = await axios.post('uni/article/news', body)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_ARTICLES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAllArticles({ commit }, body) {
      try {
        const response = await axios.post('uni/article/news', body)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_ARTICLES_ALL', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getArticlesHL({ commit }, body) {
      try {
        const response = await axios.post('uni/article/news/highlights', body)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_ARTICLES_HL', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getArticleById({ commit }, id) {
      try {
        const response = await axios.get(`uni/article/news/${id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ARTICLE', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getCategory({ commit }, params) {
      try {
        const response = await axios.get('uni/article_categories/all', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CATEGORY', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

  },
}
