import { ResourceCode } from '@/const/code'

export default [
  {
    path: '/studentEvaluate',
    name: 'studentEvaluate',
    component: () => import('@/views/set-of-criteria-objects/List.vue'),
    meta: {
      resource: ResourceCode.STUDENT_EVALUATE,
    },
  },
]
