export default [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/login/ssoCallBack',
    name: 'LoginSsoCallBack',
    component: () => import('@/views/auth/LoginSsoCallBack.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/logout/ssoCallBack',
    name: 'LogoutSsoCallBack',
    component: () => import('@/views/auth/LogoutSsoCallBack.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
    },
  },
  {
    path: '/auth/notAuthorized',
    name: 'notAuthorized',
    component: () => import('@/views/auth/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
    },
  },
]
