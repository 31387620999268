import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/digital-libraries/list-of-publications',
    name: 'listOfPublications',
    component: () => import('@/views/digital-library/AnPham.vue'),
    meta: {
      pageTitle: 'Ấn phẩm',
      resource: ResourceCode.PORTAL_PUBLICATION,
    },
  },
  {
    path: '/digital-libraries/list-of-borrowing',
    name: 'listOfBorrowing',
    component: () => import('@/views/digital-library/PhieuMuon.vue'),
    meta: {
      pageTitle: 'Phiếu mượn',
      resource: ResourceCode.PORTAL_BORROWING,
    },
  },
]
