import { PermissionCode, ResourceCode } from '@/const/code';

export default [
  {
    path: '/searchCertificate',
    name: 'searchCertificate',
    component: () => import('@/views/certificate/SearchCertificate.vue'),
    meta: {
      resource: ResourceCode.PORTAL_SEARCH_CERTIFICATE,
    },
  },
  {
    path: '/credentialProcessHistory',
    name: 'credentialProcessHistory',
    component: () => import('@/views/certificate/CredentialProcessHistory/CredentialProcessHistory.vue'),
    meta: {
      resource: ResourceCode.PORTAL_CREDENTIAL_PROCESS_HISTORY,
    },
  },
  {
    path: '/listCredentials',
    name: 'listCredentials',
    component: () => import('@/views/certificate/ListCredentials.vue'),
    meta: {
      resource: ResourceCode.LIST_CREDENTIALS,
    },
  },
]
