import { PermissionCode, ResourceCode } from '@/const/code';

export default [
  {
    path: '/announcement/university',
    name: 'universityAnnouncement',
    component: () => import('@/views/announcement/University.vue'),
    meta: {
      resource: ResourceCode.PORTAL_UNIVERSITY_ANNOUNCEMENT,
    },
  },
  {
    path: '/announcement/graduate',
    name: 'graduateAnnouncement',
    component: () => import('@/views/announcement/University.vue'),
    meta: {
      resource: ResourceCode.PORTAL_GRADUATE_ANNOUNCEMENT,
    },
  },
]
