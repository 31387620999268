import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    navMenuItems: [],
  },
  getters: {
    navMenuItems: state => state.navMenuItems,
  },
  mutations: {
    SET_NAV_MENU_ITEMS: (state, data) => { state.navMenuItems = data },
  },
  actions: {
    async getNavMenuItems({ commit }) {
      try {
        const response = await axios.get('auth/menus/portal/all')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { menus } = data.data
          commit('SET_NAV_MENU_ITEMS', menus)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updatePortalProgramme({ commit }, params) {
      try {
        const response = await axios.put(`uni/students/${params.studentId}/portalProgrammes`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
