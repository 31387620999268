export default {
  // Endpoints
  loginEndpoint: '/auth/login',
  refreshEndpoint: '/auth/refresh',
  verifyEndpoint: '/auth/verify',
  verifySsoEndpoint: '/auth/verifySso',
  forgotPassword: '/auth/forgotPassword',
  verifyForgotPassword: '/auth/verifyForgotPassword',
  resetPassword: '/auth/resetPassword',
  tokenType: 'Bearer',
  storageAccessTokenKeyName: 'qldtAccessToken',
  storageRefreshTokenKeyName: 'qldtRefreshToken',
  storageUserName: 'qldtUser',
  storageIdTokenKeyName: 'qldtIdToken',
  storageLoginTypeKeyName: 'qldtLoginType',
}
