export default [
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import('@/views/forgotPassword/index.vue'),
    meta: {
      // resource: 'NEWS',
    },
  },
]
