export default [
  {
    path: '/examination',
    name: 'examination',
    component: () => import('@/views/examination/index.vue'),
    meta: {
      // resource: 'NEWS',
    },
  },
]
