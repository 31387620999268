import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.programmeSubjectGroup,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    resourceName: {
      fullName: 'Nhóm học phần',
      shortName: 'Nhóm',
    },
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
