import { ResourceCode } from '@/const/code'

export default [
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/News.vue'),
    meta: {
      // resource: 'NEWS',
    },
  },
  {
    path: '/news/:newsId',
    name: 'newsDetail',
    component: () => import('@/views/announcement/University.vue'),
    meta: {
      resource: ResourceCode.PORTAL_UNIVERSITY_ANNOUNCEMENT,
    },
  },
]
